import { Login } from '@/features/auth';
import React from 'react';

export const LoginPage = () => {
  return <Login />;
};

export const getServerSideProps = (ctx: any) => {
  return {
    props: {},
  };
};

export default LoginPage;
